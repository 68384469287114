<template>
  <div class="login-container minSize">
    <Header></Header>
    <div class="finish">
      <div class="content">
        <my-icon
          class="succesIcon"
          :fontSize="48"
          :target="'succesIcon'"
          :iconName="'iconwangluojiance_wancheng_48_lv'"
        ></my-icon>
        <div class="title">{{content || $t('setUp.register')}}</div>
        <div class="jump">
          <span v-if="$i18n.locale === 'zh-CN'">页面 {{count}} 秒后跳转 </span>
          <span v-else>page Resend in {{count}} s </span>
          <span class="jumpBtn" @click="onJump">{{$t('login.goHands')}}</span>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Vue from "vue"

import Header from "@/components/header/Header";
import Footer from "@/components/footer/Footer";
import Back from "@/components/back/Back";

export default {
  components: {
    Header,
    Footer,
    Back
  },
  props: {
    content: { default: ""},
  },
  data() {
    return {
      timer: null,
      count: 10,
    };
  },
  mounted() {
    this.goto();
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    // 返回倒计时
    goto() {
      let that = this;
      if (!that.timer) {
        that.timer = setInterval(() => {
          if (that.count > 1 && that.count <= 10) {
            that.count = that.count - 1;
          } else {
            that.$router.push({
              name: "login",
              params: {
                isClick: true,
              },
            });
            clearInterval(that.timer);
          }
        }, 1000);
      }
    },
    // 跳转
    onJump() {
      let that = this;
      that.$router.push({
        name: "login",
        params: {
          isClick: true,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @mixin pub_style($height,$size,$weight,$color){
    height:$height;
    line-height: $height;
    color:$color;
    font-size: $size;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight:$weight;
    margin-top: 30px;
    text-align: center;
  }
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.finish .content {
  height: 200px;
  position: absolute;
  left: calc(50% - 150px);
  top: calc(50% - 100px);
  .succesIcon {
    width: 48px;
    margin: 0 auto;
    margin-top: 20px;
  }
  .title {
      width:300px;
      @include pub_style(30px,24px,500,#333333);
      margin: 0 auto;
    }
    .jump {
      @include pub_style(20px,14px,400,#999999);
      .jumpBtn {
        color: #1ab370;
        cursor: pointer;
      }
    }
}
</style>